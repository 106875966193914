<template>
  <div class="login fill-height grey darken-3">
      <v-snackbar v-model="showSnackbar" top :color="snackbarColor">
        <span class="font-weight-bold">{{snackbarMessage}}</span>
      </v-snackbar>
    <register-new-password v-if="auth.showNewPassword" :email="form.email" :on-submit="onSubmitNewPassword" :on-cancel="onCancelNewPassword" />
    <v-layout v-else justify-center align-center fill-height class="px-2">
      <v-card class="pb-4 px-3">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <v-form style="max-width: 320px" @submit.prevent.stop="onSubmit">
            <v-card-title>
              Login
            </v-card-title>
            <v-card-subtitle>
              acesso ao painel de vendas
            </v-card-subtitle>
            <v-card-text>

              <v-row>
                <v-col cols="12">
                  <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                  >
                    <v-text-field
                        v-model="form.email"
                        placeholder="E-mail"
                        prepend-icon="mdi-at"
                        type="email"
                        required
                        :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required"
                  >
                    <v-text-field
                        v-model="form.password"
                        placeholder="Senha"
                        prepend-icon="mdi-key-variant"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        autocomplete="new-password"
                        required
                        :error-messages="errors"
                        @keypress="$refs.observer.validate()"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <div class="text-right">
              </div>

            </v-card-text>
            <v-card-actions>
              <v-layout justify-center>
                <v-btn :disabled="invalid" v-if="auth.status <= 0" class="px-10" color="primary" type="submit">
                  Login
                </v-btn>
                <v-progress-linear v-if="auth.status === 1" indeterminate />
              </v-layout>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import RegisterNewPassword from "@/views/register-new-password";

extend("email", {
  ...email,
  message: "e-mail inválido",
});

extend("required", {
  ...required,
  message: 'obrigatório',
});

setInteractionMode("eager");

export default {
  components: {
    RegisterNewPassword,
    ValidationObserver,
    ValidationProvider,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(vm.$store.getters["auth/isAuthenticated"]) {
        return vm.$router.push({name: 'Home'})
      } else {
        return next()
      }
    })
  },
  data: () => ({
    form: {
      email: '',
      password: ''
    },
    showSnackbar: false,
    snackbarMessage: '',
    snackbarColor:  '',
    showPassword: false,
    showNewPassword: false,
  }),
  computed: {
    auth() {
      return this.$store.state.auth || {};
    }
  },
  methods: {
    onSubmit() {
      if(this.$refs.observer.validate()) {
        this.$store.dispatch("auth/authentication", this.form)
            .then(() => {
              this.$router.push({name: 'Home'})
            })
            .catch((err) => {

              if(!err) return this.alertSnackbar("Erro inesperado verifique a conexão e tente novamente.", 'error')

              if (err.status === 428) {
                return this.$store.commit('auth/setShowNewPassword', true)
              }
              this.alertSnackbar("login inválido", 'error')
            })
      }
    },
    onSubmitNewPassword(email, password) {
      this.$store.dispatch("users/updatePassword", {email, password})
          .then(() => {
            this.$store.commit('auth/setShowNewPassword', false)
            this.alertSnackbar('Senha cadastrada com sucesso, faça o login com a nova senha.','success')
            this.form.password = undefined
          })
          .catch(() => {
            this.alertSnackbar("erro ao cadastrar, tente novamente",'error')
          })
    },
    onCancelNewPassword() {
      this.$router.push({name: 'Login'})
      this.$store.dispatch('auth/revokeAthentication')
    },
    alertSnackbar(message, status) {
      this.showSnackbar = true
      this.snackbarMessage = message
      this.snackbarColor = status
    }
  }
}
</script>
