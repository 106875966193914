<template>
  <v-layout justify-center align-center fill-height class="register-new-password px-2">
    <v-card class="pb-4 px-3">
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-form style="max-width: 320px" @submit.prevent.stop="_onSubmit(email, password)">
          <v-card-title>
            Login
          </v-card-title>
          <v-card-subtitle>
            Sua senha deve ser alterada, cadastre uma nova senha.
          </v-card-subtitle>
          <v-card-text>

            <v-row>
              <v-col cols="12">
                <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    rules="required|password:6"
                >
                  <v-text-field
                      v-model="password"
                      name="password"
                      placeholder="Nova Senha"
                      prepend-icon="mdi-key-variant"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      autocomplete="new-password"
                      required
                      :error-messages="errors"
                      ref="password"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                    v-slot="{ errors }"
                    name="password_confirmation"
                    rules="required|password:6|password_confirm:@password"
                >
                  <v-text-field
                      v-model="confirmPassword"
                      name="password_confirmation"
                      placeholder="Repita a Nova Senha"
                      prepend-icon="mdi-key-variant"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      autocomplete="new-password"
                      required
                      :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <div class="text-right">
            </div>

          </v-card-text>
          <v-card-actions>
            <v-layout column justify-center>
              <v-btn :disabled="invalid" v-if="users.status <= 0" class="px-10" color="primary" type="submit">
                Cadastrar
              </v-btn>
              <v-btn small v-if="users.status <= 0" class="px-10 mt-5" color="" type="button" @click="onCancel">
                Cancelar
              </v-btn>
              <v-progress-linear v-if="users.status === 1" indeterminate />
            </v-layout>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-layout>
</template>

<script>
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

extend("password", {
  ...min,
  message: 'senha deve ter no mínimo 6 dígitos',
});

extend('password_confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'as senhas devem ser iguais'
});

extend("required", {
  ...required,
  message: 'obrigatório',
});

setInteractionMode("eager");

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: ['email','onSubmit','onCancel'],
  data: () => ({
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  }),
  computed: {
    users() {
      return this.$store.state.users || {};
    }
  },
  methods: {
    _onSubmit(email, password) {
      if(this.$refs.observer.validate()) {
        this.onSubmit(email, password)
      }
    }
  }
}
</script>
